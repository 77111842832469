import React, { useState, useRef, useEffect } from "react";
import * as Portal from "@radix-ui/react-portal";
import { AnimatePresence, motion } from "framer-motion";
import { Container } from "../Container";
import { Heading } from "../ui/Heading";
import { useCoverageEditorContext } from "../CoverageEditor";
import { useAppLayerContext } from "@/shared/contexts/AppLayer";
import MultipetToggle from "../MultipetToggle";
import PetDropdown from "./MultiPetSelectDropdown/PetDropdown";
import { useMultiPetSelectContext } from ".";
import { Button } from "../ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDog, faCat, faChevronDown, faPlus, faPaw, faCircleInfo } from "@fortawesome/pro-solid-svg-icons";
import { Species } from "spot-types/entities/Species";
import PetModal from "../PetModal";

type StickyMenuProps = {
    isVisible: boolean;
};

export const StickyMenu = ({ isVisible }: StickyMenuProps) => {
    const [shouldRender, setShouldRender] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const editorConfig = useCoverageEditorContext();
    const { title, getModalInitialValues } = editorConfig;
    const { appState } = useAppLayerContext();
    const { isApplyAllHidden, isMultiPetLinked, quoteQuery } = appState;
    const quote = quoteQuery?.data;
    const policies = quote?.policies;
    const { policyPets, handleActivePetChange, petModalConfig, activePet } = useMultiPetSelectContext();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const defaultCoverageSettings = policies?.[0]?.coverageSettings;

    useEffect(() => {
        const timer = setTimeout(() => {
            setShouldRender(true);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    const isBadgeVisible = isMultiPetLinked && policyPets.length > 1;

    const handleAddPet = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleTouchStart = (e: React.TouchEvent) => {
        handleAddPet();
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            handleAddPet();
        }
    };

    const dropdownTrigger = isBadgeVisible ? (
        <Button
            variant="invert"
            className="flex h-11 items-center justify-between rounded-md bg-background-primary-invert px-0 text-content-primary-invert focus:outline-none sm:w-fit sm:px-2"
        >
            <div className="flex items-center space-x-2">
                <FontAwesomeIcon icon={faPaw} className="size-5" />
                <span>{policyPets.length} Pets</span>
                <FontAwesomeIcon icon={faCircleInfo} className="size-5" />
            </div>
        </Button>
    ) : (
        <Button
            type="button"
            variant="invert"
            className="flex h-11 w-full max-w-[180px] items-center justify-between rounded-md bg-background-primary-invert px-0 text-base text-content-primary-invert focus:outline-none sm:w-fit sm:max-w-[240px]"
        >
            <div className="flex min-w-0 grow items-center gap-1">
                <FontAwesomeIcon icon={activePet?.species === Species.Dog ? faDog : faCat} className="size-5 shrink-0" />
                <span className="truncate text-sm">{activePet ? activePet.name : "Select a pet"}</span>
                <FontAwesomeIcon icon={faChevronDown} className="shrink-0" />
            </div>
        </Button>
    );

    const petModalTrigger = (
        <Button
            loading={quoteQuery?.isLoading}
            loadingSpinnerPosition="start"
            onClick={handleAddPet}
            onTouchStart={handleTouchStart}
            onKeyDown={handleKeyDown}
            variant="invert"
            size="sm"
            className="flex h-11 w-min shrink-0 items-center justify-between whitespace-nowrap rounded-md px-0 py-3 text-xs text-content-primary-invert"
            startDecorator={<FontAwesomeIcon icon={faPlus} className="size-4 text-content-primary-invert" />}
        >
            Add a Pet (10% Off)
        </Button>
    );

    return (
        <Portal.Root>
            <AnimatePresence>
                {isVisible && shouldRender && (
                    <motion.div
                        initial={{ y: -120 }}
                        animate={{ y: 0 }}
                        exit={{ y: -120 }}
                        transition={{ duration: 0.2 }}
                        className="fixed inset-x-0 top-0 z-[45] w-full bg-background-primary shadow-sm"
                    >
                        <div className="flex w-full flex-col">
                            <Container>
                                <div className="flex items-center justify-between py-3">
                                    <Heading level="h1" className="text-lg font-bold">
                                        {title}
                                    </Heading>
                                    {isApplyAllHidden ? null : <MultipetToggle policies={policies} />}
                                </div>
                            </Container>
                            <div ref={dropdownRef} className="bg-background-primary-invert py-2">
                                <Container>
                                    <div className="flex items-center justify-between gap-2">
                                        <div className="min-w-0 grow">
                                            <PetDropdown
                                                policyPets={policyPets}
                                                handleActivePetChange={handleActivePetChange}
                                                petModalConfig={petModalConfig}
                                                triggerButton={dropdownTrigger}
                                                isMultiPetLinked={isMultiPetLinked}
                                                containerRef={dropdownRef}
                                            />
                                        </div>
                                        <div className="shrink-0">
                                            <PetModal
                                                initialValues={{ policies: [getModalInitialValues(defaultCoverageSettings)] }}
                                                triggerButton={petModalTrigger}
                                                isOpen={isModalOpen}
                                                handleOpen={() => setIsModalOpen(true)}
                                                onClose={handleCloseModal}
                                                config={petModalConfig}
                                                handleActivePetChange={handleActivePetChange}
                                            />
                                        </div>
                                    </div>
                                </Container>
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </Portal.Root>
    );
};
