import { useMemo } from "react";
import { useQueries } from "@tanstack/react-query";
import axios from "axios";
import { QuoteAPI } from "../utils/QuoteAPI";
import { PetUnderwriterType } from "spot-types/entities/PetQuote";
import { Quote } from "../types/Quote.interface";
import { Coverage } from "spot-types/entities/Coverage";
import { PublicConfig } from "../PublicConfig";
import { useDeepCompareMemo } from "@react-hookz/web";

/**
 * `usePetPlans` Custom Hook
 *
 * This hook is designed to manage the fetching, updating, and default state of Pet Plans.
 *
 * @param quote - The Quote object to fetch.
 * @param underwriter - Underwriter type.
 *
 * @returns
 *  - petPlansQuery: The React Query object containing the current quote state, refetch, etc.
 *  - isPetPlansUpdating: A boolean flag indicating if any plan mutation is currently pending.
 */

export type PetPlan = { baseCoverage: Coverage; riders: Coverage[] };
export const usePetPlans = (quote: Quote | undefined, underwriter: PetUnderwriterType) => {
    const quoteApi = new QuoteAPI(underwriter);
    const { id: quoteId, policies, ratingZipcode } = quote || {};

    const policiesMemo = useMemo(() => {
        return policies || [];
    }, [policies]);

    const queries = policiesMemo.map(policy => ({
        queryKey: ["petPlan", quoteId, ratingZipcode, policy.id, policy.species, policy.breedID, policy.gender, policy.age],
        queryFn: async () => {
            if (!quoteId || !policy.id || !ratingZipcode) {
                throw new Error("Missing required parameters");
            }

            try {
                const plans = (await quoteApi.getPlans(quoteId, policy.id)) || [];

                // Filter and transform plans based on underwriter and specific conditions
                if (underwriter === "ptz-us") {
                    return plans
                        .map(p => {
                            if (p.baseCoverage.details?.annualLimit?.value === PublicConfig.UNLIMITED_ANNUAL_LIMIT_VALUE) {
                                // Transforming the value and symbol for annualLimit
                                return {
                                    ...p,
                                    baseCoverage: {
                                        ...p.baseCoverage,
                                        details: {
                                            ...p.baseCoverage.details,
                                            annualLimit: {
                                                symbol: "Unlimited",
                                                value: PublicConfig.UNLIMITED_ANNUAL_LIMIT_VALUE
                                            }
                                        }
                                    }
                                };
                            }
                            return p;
                        })
                        .filter(p => {
                            const annualLimitValue = p.baseCoverage.details?.annualLimit?.value;
                            return typeof annualLimitValue === "number" && (annualLimitValue <= 10000 || annualLimitValue === 0);
                        })
                        .filter(p => {
                            const deductible = p.baseCoverage.details?.deductible?.value;
                            return typeof deductible === "number" && deductible !== 300;
                        });
                }

                return plans;
            } catch (err) {
                if (axios.isAxiosError(err)) throw err;
                console.error("Unexpected error in fetching pet plans:", err);
                throw new Error("Failed to fetch pet plans");
            }
        }
    }));

    const queryResults = useQueries({ queries });
    const memoizedQueryResults = useDeepCompareMemo(() => queryResults, [queryResults]);

    const petPlans = useMemo(() => {
        return memoizedQueryResults.reduce(
            (acc, result, index) => {
                const policy = policiesMemo[index];
                if (policy && policy.id && result.data) {
                    const petId = policy.id;
                    acc[petId] = result.data;
                }
                return acc;
            },
            {} as Record<string, PetPlan[]>
        );
    }, [policiesMemo, memoizedQueryResults]);

    const isPetPlansUpdating = queryResults.some(result => result.isFetching || result.isLoading);

    return { petPlansQuery: queryResults, petPlans, isPetPlansUpdating };
};
