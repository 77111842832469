// packages
import React, { useCallback, useEffect, useState, useRef, useMemo, FunctionComponent, ReactNode, createContext, useContext } from "react";
import { useDeepCompareMemo } from "@react-hookz/web";
import { AnimatePresence, motion } from "framer-motion";

// components
import { Button } from "@/shared/components/ui/Button";
import { RadioButtonGroup } from "@/shared/components/ui/RadioButtonGroup";
import { FormField } from "@/shared/components/FormField";
import LoaderWithText from "@/shared/components/LoaderWithText";
import { ReimbursementTermsModal } from "@/shared/components/ReimbursementTermsModal";
import { CoverageIconGrid } from "@/shared/components/CoverageIconGrid";
import { PreventiveCards } from "@/shared/components/PreventiveCards";
import { Heading } from "@/shared/components/ui";
import AdditionalPlans from "@/shared/components/AdditionalPlans";
import DynamicOptionsField from "@/shared/components/DynamicOptionsField";
import { MultiPetSelect } from "./MultiPetSelect";
import MultipetToggle from "./MultipetToggle";
import TakeoverProvision from "./TakeoverProvision";
import { CustomizationSlot } from "./CustomizationSlot";
import { PriceInfo } from "./PriceInfo";

// utils
import { CoverageUtils, CoverageValue } from "@/shared/utils/CoverageUtils";
import { PresetCoverageLevel, PreventiveConfig } from "@/shared/utils/CoverageUtils";
import { BuilderUtils } from "@/shared/utils/BuilderUtils";
import { UIUtils } from "../utils/UIUtils";
import { MAX_PET_NAME_LABEL } from "../utils/constants";
import { AnalyticsUtils } from "../utils/AnalyticsUtils";

// hooks
import { useAppLayerContext } from "@/shared/contexts/AppLayer";
import { useFormParentContext } from "@/shared/contexts/FormParent";
import { useModal } from "../hooks/useModal";
import { usePetPlans } from "@/shared/hooks/usePetPlans";
import { useStatsig } from "../hooks/useStatsig";

// types
import { CoverageSettings, Option, Quote, Pet, InitialPetValues } from "@/shared/types/Quote.interface";
import { FormStepProps } from "@/shared/types/Form";
import { PetUnderwriterType } from "spot-types/entities/PetQuote";
import { DialogProps } from "@/shared/types/Modals";
import { PetModalConfig } from "./PetModal";

// config
import { PublicConfig } from "../PublicConfig";

// media
import InfoIcon from "@/shared/media/icons/CircleInfoProRegular.svg";

export type CoverageModalTerms = "reimbursement" | "annualLimit" | "annualDeductible";

type CustomCoverageProps = {
    editorConfig: {
        formId: string;
        title?: string;
        coveragePresetData?: PresetCoverageLevel[];
        termsInModal?: CoverageModalTerms[];
        samplePolicyUrl?: string;
        modalContent: React.ComponentType<DialogProps>;
        preventiveConfig: PreventiveConfig;
        includeTransactionFee?: boolean;
        exclusions?: ReactNode;
        customizationSlot?: ReactNode;
        petModalConfig: PetModalConfig;
        getModalInitialValues: (defaultCoverageSettings?: CoverageSettings) => InitialPetValues;
        showTakeoverProvision?: boolean;
        priorityCode?: string;
    };
};

type CoverageEditorProps = FormStepProps<Quote> & CustomCoverageProps;

export function CoverageEditor(props: CoverageEditorProps) {
    const {
        formId,
        title = "Create your plan",
        coveragePresetData,
        termsInModal = ["annualDeductible", "annualLimit", "reimbursement"],
        samplePolicyUrl = "",
        modalContent,
        preventiveConfig,
        includeTransactionFee = true,
        exclusions,
        customizationSlot,
        petModalConfig,
        showTakeoverProvision = false,
        priorityCode
    } = props?.editorConfig || {};
    const modal = useModal();

    // local state
    const [customCoverageSettings, setCustomCoverageSettings] = useState<CoverageValue | null>(null);
    const [activePetIndex, setActivePetIndex] = useState(0);
    const coverageLevelIndex = CoverageUtils.DEFAULT_COVERAGE_INDEX;

    const initialRef = useRef(false);

    // form state
    const { setValue, getValues, watch } = useFormParentContext<Quote>();

    const quote = watch();
    const policies = watch("policies");

    // hooks
    const { appState, updateAppState } = useAppLayerContext();
    const { isMultiPetLinked, updateQuote, isQuoteUpdating, isApplyAllHidden, underwriter, breakpoint } = appState;
    const { petPlans, isPetPlansUpdating } = usePetPlans(quote, underwriter as PetUnderwriterType);

    const queryParams = AnalyticsUtils.getQueryParams(quote?.extra?.queryParams);
    const builderUtils = new BuilderUtils(underwriter as PetUnderwriterType);

    // #statsig:start - coverage-step-multi-pet
    const { multiPetVariant } = useStatsig({
        layerId: PublicConfig.STATSIG_LAYER_ID_MAIN,
        layerParameters: [{ key: "multiPetVariant", defaultValue: "default" }] as const,
        queryParams
    });
    // #statsig:end

    // Initial data
    const allPets = useDeepCompareMemo(() => policies ?? [], [policies]);
    const activePet = useDeepCompareMemo(() => policies?.[activePetIndex], [policies, activePetIndex]);
    const isAnnualBilling = appState.isAnnualBilling;
    const activePetAllPlans = useMemo(() => petPlans?.[activePet?.id ?? ``] ?? [], [activePet?.id, petPlans]);
    const policyPets: Option[] = useMemo(() => allPets.map(pet => ({ value: pet.id ?? ``, label: pet.name ?? `` })), [allPets]);
    const priceInfoData = useMemo(
        () => CoverageUtils.getPriceInfoData({ value: quote, includeTransactionFee: false, petIdToShow: !isMultiPetLinked ? activePet?.id : undefined }),
        [activePet?.id, isMultiPetLinked, quote]
    );

    // format pet plan API data for UI components
    const allCoverageOptions = useMemo(
        () => (!!activePetAllPlans.length ? CoverageUtils.formatCustomCoverageData(activePetAllPlans, isAnnualBilling) : null),
        [activePetAllPlans, isAnnualBilling]
    );

    const { coverageTypes, annualLimits, annualDeductibles, reimbursementRates, preventiveCareOptions } = allCoverageOptions || {};

    // find options that have multiple values for matching multipet coverage settings
    const coverageValuesToMatch = useMemo(() => {
        if (!!allCoverageOptions) {
            const valuesWithMultipleOptions = CoverageUtils.findKeysWithMultipleOptions(allCoverageOptions);
            const matchCriteriaKeys = CoverageUtils.convertKeysToMatchCriteria(valuesWithMultipleOptions);
            return matchCriteriaKeys;
        }
        return [];
    }, [allCoverageOptions]);

    const allPetsHaveCoverageSettings = allPets.every(pet => {
        const amounts = pet?.coverageSettings?.amounts;
        return amounts && Object.values(amounts).every(value => value !== undefined) && !!pet.basePrice?.value;
    });

    const transactionFee = includeTransactionFee ? (getValues("transactionFee.value") ?? 0) : 0;
    const coveragePresets = useMemo(() => {
        if (!!activePet?.id && !!petPlans && !!coveragePresetData) {
            const activePetPlans = petPlans[activePet.id];
            if (!!activePetPlans) {
                return CoverageUtils.getPresetPlansFromApiData({ petPlans: activePetPlans, presetCoverageLevels: coveragePresetData });
            }
        }
        return [];
    }, [activePet?.id, petPlans, coveragePresetData]);

    const allPetsHaveSameCoverages = useMemo(() => {
        if (!!allPets.length) {
            const allCoverageSettings = allPets.map(pet => pet?.coverageSettings);
            if (!!allCoverageSettings.length) {
                return CoverageUtils.allPetCoverageSettingsMatch(allCoverageSettings, coverageValuesToMatch);
            }
        }
        return false;
    }, [allPets, coverageValuesToMatch]);

    const allPetsHaveFetchedPlans = useMemo(() => {
        if (!!petPlans && !isPetPlansUpdating) {
            const allPlanIds = Object.keys(petPlans);
            return allPets.every(pet => !!pet.id && allPlanIds.includes(pet.id));
        }
        return false;
    }, [allPets, isPetPlansUpdating, petPlans]);

    const activePetCoverage = useDeepCompareMemo(() => {
        const coverage = !!activePet && customCoverageSettings ? customCoverageSettings : coveragePresets[coverageLevelIndex];
        return coverage;
    }, [customCoverageSettings, coveragePresets, coverageLevelIndex, activePet]);

    const getWellnessLevel = (coverageSettings?: CoverageSettings) => {
        if (!coverageSettings) return "None";
        const wellnessCoverage = coverageSettings?.coverages?.find(c => c.type?.includes(`wellness`))?.type;
        return wellnessCoverage === "wellness-gold" ? "wellness-gold" : wellnessCoverage === "wellness-platinum" ? "wellness-platinum" : "None";
    };

    const [coverageLevel, setCoverageLevel] = useState(
        activePet?.coverageSettings?.coverages?.some(coverage => coverage.type === "illness") ? "accidentAndIllness" : "accidentOnly"
    );
    const [annualLimitValue, setAnnualLimitValue] = useState(activePet?.coverageSettings?.amounts?.annualLimit?.toString());
    const [annualDeductibleValue, setAnnualDeductibleValue] = useState(activePet?.coverageSettings?.amounts?.annualDeductible?.toString());
    const [reimbursementValue, setReimbursementValue] = useState(activePet?.coverageSettings?.amounts?.reimbursementRate?.toString());
    const [wellnessValue, setWellnessValue] = useState(getWellnessLevel(activePet?.coverageSettings));

    const updatePricing = useCallback(
        (newCoverageSettings: CoverageSettings) => {
            if (!!petPlans && !!activePet?.id) {
                const calculatedData = CoverageUtils.calculatePriceData({
                    coverageSettings: newCoverageSettings,
                    policies: allPets,
                    isMultiPetLinked,
                    activePetId: activePet.id,
                    petPlans: petPlans,
                    coverageValuesToMatch,
                    isAnnualBilling,
                    transactionFee
                });

                // Restructure the data to fit our new app state structure
                const newCalculatedPriceData = {
                    preDiscountPrice: calculatedData.preDiscountPrice,
                    discountAmount: calculatedData.discountAmount,
                    finalPrice: calculatedData.finalPrice,
                    singlePetPriceData: {
                        [activePet.id]: calculatedData.activePetPriceData
                    }
                };

                // Update the app state with the new calculated price data
                const currentValues = appState.calculatedPriceData;
                updateAppState({
                    calculatedPriceData: {
                        ...currentValues,
                        ...newCalculatedPriceData,
                        singlePetPriceData: {
                            ...currentValues?.singlePetPriceData,
                            ...newCalculatedPriceData.singlePetPriceData
                        }
                    }
                });
            }
        },
        [activePet?.id, allPets, appState.calculatedPriceData, coverageValuesToMatch, isAnnualBilling, isMultiPetLinked, petPlans, transactionFee, updateAppState]
    );

    const updateCoverageSettings = useCallback(
        (newCoverageSettings: CoverageSettings | null) => {
            if (!newCoverageSettings) return;
            setCustomCoverageSettings({ name: `custom`, config: newCoverageSettings });

            updatePricing(newCoverageSettings);

            const { policies } = getValues();
            if (!!policies?.length) {
                if (isMultiPetLinked) {
                    policies.forEach((policy, index) => {
                        setValue(`policies.${index}.coverageSettings`, newCoverageSettings);
                    });
                } else {
                    setValue(`policies.${activePetIndex}.coverageSettings`, newCoverageSettings);
                }
            }

            const allValues = getValues();
            updateQuote?.mutate(allValues);
        },
        [activePetIndex, getValues, isMultiPetLinked, setCustomCoverageSettings, setValue, updatePricing, updateQuote]
    );

    const handleCustomAmounts = (change: { setting: "annualLimit" | "reimbursementRate" | "annualDeductible"; value: string }) => {
        if (!activePetCoverage || !activePetAllPlans) {
            console.error("Active pet or plans data is undefined.");
            return;
        }

        const updatedAmounts = {
            ...activePetCoverage.config?.amounts,
            [change.setting]: Number(change.value)
        };

        const updatedCoverageSettings: CoverageSettings = {
            ...activePetCoverage.config,
            amounts: updatedAmounts
        };

        const matchingPlan = CoverageUtils.getPlanDataFromCoverageSettings(activePetAllPlans, updatedCoverageSettings, coverageValuesToMatch);

        if (matchingPlan) {
            const newCoverageSettings = CoverageUtils.getCoverageSettingsFromPlanData(
                matchingPlan,
                wellnessValue === "None" ? undefined : (wellnessValue as "wellness-gold" | "wellness-platinum")
            );
            updateCoverageSettings(newCoverageSettings);
        } else {
            console.error("No matching plan found for the new coverage settings.");
        }
    };

    const handleCoverageLevelChange = (planLevel: "accidentOnly" | "accidentAndIllness") => {
        let newCoverages = [...(activePet?.coverageSettings?.coverages || [])];

        // Adjust coverage based on the coverageLevel change
        const hasIllnessCoverage = newCoverages.some(c => c.type === "illness");
        if (planLevel === "accidentAndIllness" && !hasIllnessCoverage) {
            // Push "illness" coverage to newCoverages with a temporary filler ID
            newCoverages.push({ type: "illness", id: "tempId", name: "Illness Coverage" });
        } else if (planLevel === "accidentOnly" && hasIllnessCoverage) {
            // Remove "illness" coverage if switching back to accidentOnly
            newCoverages = newCoverages.filter(c => c.type !== "illness");
        }

        const updatedCoverageSettings: CoverageSettings = {
            ...activePet?.coverageSettings,
            coverages: newCoverages
        };

        const matchingPlan = CoverageUtils.getPlanDataFromCoverageSettings(activePetAllPlans, updatedCoverageSettings, coverageValuesToMatch);

        if (matchingPlan) {
            const newCoverageSettings = CoverageUtils.getCoverageSettingsFromPlanData(
                matchingPlan,
                wellnessValue === "None" ? undefined : (wellnessValue as "wellness-gold" | "wellness-platinum")
            );
            updateCoverageSettings(newCoverageSettings);
        } else {
            console.error("No matching plan found for the new coverage settings.");
        }
    };

    const handlePreventiveChange = (wellnessType: string) => {
        // When wellnessType is "None", filter out all wellness coverages, otherwise construct new wellness coverage settings
        const isRemovingWellness = wellnessType === "None";

        // Clone existing coverages, excluding any existing wellness coverage if "None" or updating to the new selection
        const newCoverages = (activePet?.coverageSettings?.coverages || []).filter(coverage => !coverage.type?.includes("wellness"));

        if (!isRemovingWellness && (wellnessType === "wellness-gold" || wellnessType === "wellness-platinum")) {
            const newCoverageSettings: CoverageSettings = {
                ...activePet?.coverageSettings,
                coverages: [{ type: wellnessType }]
            };

            const matchingPlan = CoverageUtils.getPlanDataFromCoverageSettings(activePetAllPlans, newCoverageSettings, coverageValuesToMatch);
            if (matchingPlan) {
                const wellnessRider = CoverageUtils.getRiderFromCoverageType(wellnessType, matchingPlan.riders);
                if (wellnessRider && wellnessRider.id) {
                    // Add the matched wellness coverage with dynamically found id
                    newCoverages.push({ type: wellnessType, id: wellnessRider.id, preventivePrice: wellnessRider.basePrice?.value });
                } else {
                    console.error("No matching wellness rider found for selected plan.");
                }
            } else {
                console.error("No matching plan found for updated wellness coverage settings.");
            }
        }

        // Proceed to update regardless of whether a new wellness plan was added or all wellness coverages were removed
        const updatedCoverageSettings = { ...activePetCoverage?.config, coverages: newCoverages };
        updateCoverageSettings(updatedCoverageSettings);
    };

    const handleActivePetChange = (value: string) => {
        const currentPolicies = getValues().policies ?? [];
        const newActivePetIndex = currentPolicies.findIndex(pet => pet.id === value);

        if (newActivePetIndex === -1) {
            console.warn("Pet not found in current policies");
            return;
        }

        setActivePetIndex(newActivePetIndex);
        const selectedPet = currentPolicies[newActivePetIndex];
        if (selectedPet && selectedPet.coverageSettings) {
            const { coverageSettings } = selectedPet;
            if (coverageSettings.amounts?.annualLimit) {
                setAnnualLimitValue(coverageSettings.amounts.annualLimit.toString());
            }
            if (coverageSettings.amounts?.annualDeductible) {
                setAnnualDeductibleValue(coverageSettings.amounts.annualDeductible.toString());
            }
            if (coverageSettings.amounts?.reimbursementRate) {
                setReimbursementValue(coverageSettings.amounts.reimbursementRate.toString());
            }
            if (coverageSettings.coverages) {
                setCoverageLevel(coverageSettings.coverages.some(coverage => coverage.type === "illness") ? "accidentAndIllness" : "accidentOnly");
                setWellnessValue(getWellnessLevel(coverageSettings));
            }
            setCustomCoverageSettings({ name: "custom", config: coverageSettings });
        }
    };

    const handleOpenModal = useCallback(
        (ModalComponent: FunctionComponent<any>, modalProps?: any) => {
            modal.openModal(ModalComponent, modalProps || {});
        },
        [modal]
    );

    useEffect(() => {
        // Initialization
        if (!isQuoteUpdating && !isPetPlansUpdating && allPetsHaveFetchedPlans && !initialRef.current && !!activePet?.coverageSettings) {
            const allPetPlansHaveSameOptions = CoverageUtils.allPetPlansHaveSameOptions(petPlans);
            const isLinked = allPetsHaveSameCoverages && allPetPlansHaveSameOptions;
            updateAppState({ isMultiPetLinked: isLinked });

            // If all pets do not have the same coverage options available, hide the "Apply to all pets" feature
            updateAppState({ isApplyAllHidden: !allPetPlansHaveSameOptions });
            initialRef.current = true;
        }
    }, [activePet?.coverageSettings, allPetsHaveFetchedPlans, allPetsHaveSameCoverages, isPetPlansUpdating, isQuoteUpdating, petPlans, updateAppState]);

    useEffect(() => {
        // If a user previously unchecked Apply to all and they have pets with different coverages, apply the active pet's coverage settings to all pets
        if (
            !isQuoteUpdating &&
            !isPetPlansUpdating &&
            allPetsHaveFetchedPlans &&
            initialRef.current &&
            isMultiPetLinked &&
            !allPetsHaveSameCoverages &&
            !!activePet?.coverageSettings
        ) {
            updateCoverageSettings(activePet.coverageSettings);
        }
    }, [activePet?.coverageSettings, allPetsHaveFetchedPlans, allPetsHaveSameCoverages, isMultiPetLinked, isPetPlansUpdating, isQuoteUpdating, updateCoverageSettings]);

    useEffect(() => {
        if (!customCoverageSettings && !!activePet?.coverageSettings) {
            setCustomCoverageSettings({ name: "custom", config: activePet.coverageSettings });
        }
    }, [activePet?.coverageSettings, customCoverageSettings]);

    useEffect(() => {
        if (!!policies && policies.length === 1 && !isPetPlansUpdating && !isQuoteUpdating) {
            updateAppState({ isApplyAllHidden: true });
        }
        if (!!policies && policies?.length > 1 && !isPetPlansUpdating && !isQuoteUpdating) {
            const allPetPlansHaveSameOptions = CoverageUtils.allPetPlansHaveSameOptions(petPlans);
            if (!allPetPlansHaveSameOptions) {
                updateAppState({ isApplyAllHidden: true, isMultiPetLinked: false });
            } else {
                updateAppState({ isApplyAllHidden: false });
            }
        }
    }, [isPetPlansUpdating, isQuoteUpdating, petPlans, policies, updateAppState]);

    const shouldShowLoader = !coveragePresets.length || !activePet || !underwriter || isPetPlansUpdating;
    return (
        <CoverageEditorProvider editorConfig={props.editorConfig}>
            <>
                {shouldShowLoader ? (
                    <div className="flex min-h-screen flex-col gap-7">
                        <Heading level="h1" className="text-lg font-bold">
                            {title}
                        </Heading>
                        <div className="flex w-full md:justify-center">
                            <LoaderWithText text="Finding the best coverage options for you..." />
                        </div>
                    </div>
                ) : (
                    <>
                        {multiPetVariant === "default" ? (
                            <>
                                <AnimatePresence initial={false} mode="sync">
                                    {["xs", "sm", "md"].includes(breakpoint ?? "") && policyPets?.length > 1 && !isMultiPetLinked && (
                                        <motion.div
                                            key={`outer-is-linked-${isMultiPetLinked}`}
                                            initial={{ height: 0 }}
                                            animate={{ height: `auto` }}
                                            exit={{ height: 0, transition: { delay: 0.15 } }}
                                            transition={{ duration: 0.15 }}
                                        >
                                            <motion.div
                                                key={`inner-is-linked-${isMultiPetLinked}`}
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1, transition: { delay: 0.15 } }}
                                                exit={{ opacity: 0 }}
                                                transition={{ duration: 0.15 }}
                                            >
                                                {/* Padding needed to prevent child border clipping caused by overflow-x-auto */}
                                                <div className="flex w-full flex-col items-start gap-3 overflow-x-auto px-[2px] py-1 sm:flex-row sm:items-center">
                                                    <RadioButtonGroup
                                                        initialValue={policyPets[activePetIndex]?.value as string}
                                                        onValueChange={value => handleActivePetChange(value)}
                                                        options={policyPets.map(pet => ({ ...pet, label: UIUtils.truncateString(pet.label, MAX_PET_NAME_LABEL) }))}
                                                        className="h-11"
                                                        disabled={isQuoteUpdating}
                                                    />
                                                </div>
                                            </motion.div>
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                                <div className="flex flex-col">
                                    <div className="grid grid-cols-2">
                                        <div className="col-span-1 flex flex-col gap-1 md:flex-row md:items-center md:gap-4">
                                            <Heading level="h1" className="text-lg font-bold">
                                                {title}
                                            </Heading>
                                            {isApplyAllHidden ? null : <MultipetToggle policies={props?.value?.policies} />}
                                            <AnimatePresence initial={false} mode="sync">
                                                {!["xs", "sm", "md"].includes(breakpoint ?? "") && policyPets?.length > 1 && !isMultiPetLinked && (
                                                    <motion.div
                                                        key={`outer-is-linked-${isMultiPetLinked}`}
                                                        initial={{ height: 0 }}
                                                        animate={{ height: `auto` }}
                                                        exit={{ height: 0, transition: { delay: 0.15 } }}
                                                        transition={{ duration: 0.15 }}
                                                    >
                                                        <motion.div
                                                            key={`inner-is-linked-${isMultiPetLinked}`}
                                                            initial={{ opacity: 0 }}
                                                            animate={{ opacity: 1, transition: { delay: 0.15 } }}
                                                            exit={{ opacity: 0 }}
                                                            transition={{ duration: 0.15 }}
                                                        >
                                                            {/* Padding needed to prevent child border clipping caused by overflow-x-auto */}
                                                            <div className="flex w-full flex-col items-start gap-3 overflow-x-auto px-[2px] py-1 sm:flex-row sm:items-center">
                                                                <RadioButtonGroup
                                                                    initialValue={policyPets[activePetIndex]?.value as string}
                                                                    onValueChange={value => handleActivePetChange(value)}
                                                                    options={policyPets.map(pet => ({ ...pet, label: UIUtils.truncateString(pet.label, MAX_PET_NAME_LABEL) }))}
                                                                    className="h-11"
                                                                    disabled={isQuoteUpdating}
                                                                />
                                                            </div>
                                                        </motion.div>
                                                    </motion.div>
                                                )}
                                            </AnimatePresence>
                                        </div>
                                        <div className="col-span-1 flex flex-col justify-end gap-1 md:flex-row md:items-center md:gap-4">
                                            {isMultiPetLinked ? (
                                                <PriceInfo
                                                    variant="all-pets-v2"
                                                    totalPrice={priceInfoData.roundedPrice}
                                                    discountsAmount={priceInfoData.discountsAmount}
                                                    policiesCount={quote?.policies?.length ?? 0}
                                                />
                                            ) : (
                                                <PriceInfo
                                                    variant="single-pet-v2"
                                                    petId={activePet?.id}
                                                    totalPrice={priceInfoData.roundedPrice}
                                                    discountsAmount={priceInfoData.discountsAmount}
                                                    policiesCount={quote?.policies?.length ?? 0}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="flex items-center justify-between">
                                    <Heading level="h1" className="text-lg font-bold">
                                        {title}
                                    </Heading>
                                    {isApplyAllHidden ? null : <MultipetToggle policies={policies} />}
                                </div>

                                <MultiPetSelect
                                    variant={multiPetVariant as "card" | "hybrid" | "dropdown"}
                                    policies={policies ?? []}
                                    activePet={activePet}
                                    setActivePetIndex={setActivePetIndex}
                                    policyPets={policyPets}
                                    handleActivePetChange={handleActivePetChange}
                                    isMultiPetLinked={isMultiPetLinked}
                                    isApplyAllHidden={isApplyAllHidden}
                                    isQuoteUpdating={isQuoteUpdating}
                                    priceInfoData={priceInfoData}
                                    updateCoverageSettings={updateCoverageSettings}
                                    policiesCount={quote?.policies?.length ?? 0}
                                    petModalConfig={petModalConfig}
                                />
                            </>
                        )}

                        <div className="flex flex-col">
                            <React.Fragment key={activePet?.id}>
                                <div className="mt-3 flex flex-col gap-4">
                                    {!!coverageTypes && coverageTypes.length > 1 && (
                                        <div className="flex flex-col gap-5">
                                            <FormField>
                                                <RadioButtonGroup
                                                    initialValue={coverageLevel}
                                                    options={coverageTypes}
                                                    onValueChange={value => {
                                                        setCoverageLevel(value);
                                                        handleCoverageLevelChange(value as "accidentOnly" | "accidentAndIllness");
                                                    }}
                                                    className="whitespace-nowrap"
                                                />
                                            </FormField>
                                        </div>
                                    )}
                                    {!!activePetCoverage && !!allPets.length && (
                                        <CustomizationSlot
                                            type="icon-grid"
                                            data={quote}
                                            formId={formId}
                                            formStepId="coverage"
                                            fallback={
                                                <CoverageIconGrid
                                                    coverageInfo={activePetCoverage.config}
                                                    species={activePet?.species}
                                                    underwriter={underwriter}
                                                    samplePolicyUrl={samplePolicyUrl}
                                                    modalContent={modalContent}
                                                />
                                            }
                                            priorityCode={priorityCode}
                                        />
                                    )}
                                </div>
                                <div className="mt-7 flex flex-col gap-4">
                                    <div className="grid gap-3 lg:grid-cols-3">
                                        {!!annualLimits && annualLimits.length > 0 && (
                                            <FormField>
                                                <DynamicOptionsField
                                                    id="coverage-annual-limit"
                                                    options={annualLimits}
                                                    label="Annual Limit"
                                                    initialValue={annualLimitValue}
                                                    onValueChange={value => {
                                                        setAnnualLimitValue(value);
                                                        handleCustomAmounts({ setting: "annualLimit", value });
                                                    }}
                                                />
                                            </FormField>
                                        )}
                                        {!!reimbursementRates && reimbursementRates.length > 0 && (
                                            <FormField>
                                                <DynamicOptionsField
                                                    id="coverage-reimbursement-rate"
                                                    options={reimbursementRates}
                                                    label="Reimbursement Rate"
                                                    initialValue={reimbursementValue}
                                                    onValueChange={value => {
                                                        setReimbursementValue(value);
                                                        handleCustomAmounts({ setting: "reimbursementRate", value });
                                                    }}
                                                />
                                            </FormField>
                                        )}
                                        {!!annualDeductibles && annualDeductibles.length > 0 && (
                                            <FormField>
                                                <DynamicOptionsField
                                                    id="coverage-annual-deductible"
                                                    label="Annual Deductible"
                                                    options={annualDeductibles}
                                                    initialValue={annualDeductibleValue}
                                                    onValueChange={value => {
                                                        setAnnualDeductibleValue(value);
                                                        handleCustomAmounts({ setting: "annualDeductible", value });
                                                    }}
                                                />
                                            </FormField>
                                        )}
                                    </div>
                                    <div className="flex w-full flex-row justify-center text-xs text-content-secondary sm:mb-0 sm:ml-auto">
                                        <Button
                                            onClick={() => handleOpenModal(ReimbursementTermsModal, { termsInModal })}
                                            className="text-xs"
                                            startDecorator={<InfoIcon className="h-4" />}
                                            variant="ghost"
                                            size="sm"
                                        >
                                            What do these terms mean?
                                        </Button>
                                    </div>
                                    {modal.render}
                                </div>
                            </React.Fragment>

                            {showTakeoverProvision && <TakeoverProvision />}

                            <CustomizationSlot
                                type="above-preventive"
                                data={quote}
                                formId={formId}
                                formStepId="coverage"
                                formData={props.value?.extra?.formData}
                                updateData={data => builderUtils.updateQuoteExtraData({ quote: props.value, newDataArray: data, updateQuote: () => updateQuote })}
                                priorityCode={priorityCode}
                            />

                            {!!activePetCoverage && underwriter && !!preventiveCareOptions && preventiveCareOptions.length > 1 && (
                                <div className="mt-7 flex flex-col gap-4 lg:mt-10">
                                    <div className="flex flex-col gap-2 text-center">
                                        <Heading level="h2" className="text-lg font-bold">
                                            Add {preventiveConfig.labels.basic} or {preventiveConfig.labels.advanced} Preventive Care Coverage
                                        </Heading>
                                        <p className="text-sm text-content-secondary">
                                            Get fixed reimbursement amounts every year for certain vaccines, wellness exams, and more, with these optional wellness packages.
                                        </p>
                                    </div>
                                    <PreventiveCards
                                        pet={activePet as Pet}
                                        preventiveOptions={preventiveCareOptions}
                                        preventiveConfig={preventiveConfig}
                                        underwriter={underwriter}
                                        wellnessLevel={wellnessValue}
                                        handleChange={value => {
                                            setWellnessValue(value);
                                            handlePreventiveChange(value);
                                        }}
                                    />
                                    {!!coverageTypes && coverageTypes.length === 1 && <AdditionalPlans underwriter={underwriter} />}
                                    {!!exclusions && <>{exclusions}</>}
                                    {!!customizationSlot && <>{customizationSlot}</>}
                                </div>
                            )}
                        </div>
                    </>
                )}
            </>
        </CoverageEditorProvider>
    );
}

const CoverageEditorContext = createContext<CustomCoverageProps["editorConfig"] | undefined>(undefined);

export const useCoverageEditorContext = () => {
    const context = useContext(CoverageEditorContext);
    if (context === undefined) {
        throw new Error("useCoverageEditorContext must be used within a CoverageEditorProvider");
    }
    return context;
};

interface CoverageEditorProviderProps {
    children: ReactNode;
    editorConfig: CustomCoverageProps["editorConfig"];
}

const CoverageEditorProvider: React.FC<CoverageEditorProviderProps> = ({ children, editorConfig }) => {
    return <CoverageEditorContext.Provider value={editorConfig}>{children}</CoverageEditorContext.Provider>;
};
